define('op-gg/components/op-gg', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    apiKey: 'RGAPI-e4ca03b2-e5ad-4f87-be72-a6e22ad3028d',
    names: '',
    opggURL: '',
    actions: {
      showOpGg: function showOpGg() {

        var that = this;

        var players = (this.get('names') + '\n').split(" dołącza do pokoju\n");
        players.pop();

        Ember.$.each(players, function (key, val) {
          Ember.$.ajax({
            type: "GET",
            url: "https://eun1.api.riotgames.com/lol/summoner/v3/summoners/by-name/" + val + "?api_key=" + that.get('apiKey')
          }).then(function (res) {
            Ember.$.ajax({
              type: "POST",
              url: "http://eune.op.gg/summoner/ajax/renew.json/",
              data: { summonerId: res.id }
            });
          });
        });

        this.set('opggURL', "http://eune.op.gg/multi/query=" + players.toString());
      }
    }

  });
});